<template>
  <div class="loginPage">
    <div class="fasPic">
      <van-image
        fit="contain"
        :src="require('@/assets/login/fas-logo-04.png')"
      />
    </div>
    <div class="fasTextPic">
      <van-image
        fit="contain"
        :src="require('@/assets/login/智慧FAS制造未来.png')"
      />
    </div>
    <div class="fasText">
      <p>业务流程信息管理软件</p>
      <p>Business Process Information Management Software</p>
    </div>
    <div class="loginButton">
      <van-button
        type="info"
        size="large"
        @click="loginhandler"
      >账号登录</van-button>
    </div>
    <div class="otherLogin">
      <van-divider
        :hairline="false"
        :style="{ borderColor: '#7f7f7f' }"
      >其他登录方式</van-divider>
      <van-grid
        square
        column-num="3"
        :border="false"
      >
        <van-grid-item
          icon="photo-o"
          text="文字"
          :style="{ visibility: 'hidden' }"
        />
        <van-grid-item
          :icon="require('@/assets/login/企业微信logo_RGB.svg')"
          text="企业微信"
          @click="clickHandler"
        />
        <van-grid-item
          icon="photo-o"
          text="文字"
          :style="{ visibility: 'hidden' }"
        />
      </van-grid>
    </div>
    <div class="companyText">
      <p>上海易达通信有限公司 版权所有</p>
      <p>©1993-2023 Shanghai Yi Da. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
import { EWechatLogin } from '@/api/user'
import { mapMutations } from 'vuex'

export default {
  name: 'loginPage',
  data() {
    return {
      flag: false
    }
  },
  created() {
    if (this.$route.query.token) {
      this.flag = true
      this.setTokenInfo(this.$route.query.token)
      this.$toast.success({
        message: '登录成功',
        duration: 500
      })
      this.$router.replace('/')
    }
  },
  methods: {
    ...mapMutations('user', ['setTokenInfo']),
    loginhandler() {
      if (this.$route.query.backTo) {
        this.$router.push({
          path: '/accountLogin',
          query: {
            backTo: this.$route.query.backTo // 就是之前页面的完整路径
          }
        })
      } else {
        this.$router.push('/accountLogin')
      }
    },
    // 企业微信登录
    async clickHandler() {
      if (this.flag) {
        return
      }
      this.flag = true
      try {
        await EWechatLogin()
        if (process.env.VUE_APP_SECRET_WcURL) {
          window.location.replace(process.env.VUE_APP_SECRET_WcURL)
        } else {
          this.$toast.fail({
            message: '企业微信登录失败!',
            duration: 500
          })
        }
      } catch (error) {
        this.$toast.fail({
          message: '企业微信登录失败!',
          duration: 500
        })
        this.flag = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loginPage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ::v-deep {
    .fasPic {
      text-align: center;
      margin-top: 30px;

      img {
        width: 300px;
        height: 200px;
      }
    }

    .fasTextPic {
      text-align: center;

      img {
        width: 240px;
        height: 40px;
      }
    }

    .fasText {
      p {
        font-size: 25px;
        font-weight: 700;
        text-align: center;

        &:nth-child(2) {
          font-weight: 700;
          font-size: 12px;
          font-style: italic;
        }
      }
    }

    .loginButton {
      text-align: center;
      margin-top: 10px;

      .van-button--info {
        width: 90%;
        height: 36px;
        border-radius: 5px;
      }
    }

    .otherLogin {
      margin-top: 30px;

      .van-divider {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .companyText {
      margin-top: 50px;

      p {
        color: #8a8a8a;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
